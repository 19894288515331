import {Box, Button, Drawer, Fab, IconButton, useMediaQuery} from "@mui/material";
import {LoginPanel} from "./login-panel";
import {Link} from "react-router-dom";
import {AppRoute} from "../route";
import {useKeycloak} from "@react-keycloak/web";
import {useEffect, useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';


type NavigationProps = {
  routes: AppRoute[]
}

const Navigation = ({routes}: NavigationProps) => {

  const {keycloak} = useKeycloak()
  
  const isMobile = useMediaQuery( '(max-width:1200px)' )

  const [open, setOpen] = useState(! isMobile)
  
  useEffect(() => {
    setOpen(! isMobile)
  }, [isMobile])

  return <>

    {!open && <Fab style={{position: "absolute", right: "20px", top: "20px", zIndex: "20" }}>
      <IconButton onClick={() => setOpen(true)}>
          <MenuIcon/>
      </IconButton>
    </Fab>
    }
    {open &&
    <Drawer
        variant={isMobile? "temporary" : "persistent"}
        anchor="left"
        open={open}
        onClose={isMobile? () => {setOpen(false)}: undefined }
    >

    <div className="menu-bar">

      <Box
          className="menu-home"
          onClick={() => {
            console.log(location);
          }}
      >
        Parcours Avenir
      </Box>
      <div className="menu-divider"/>

      <Box className="menu-login">
        <LoginPanel/>
      </Box>

      <div className="menu-divider"/>

      {routes.map((route) => {
        const Icon = route.icon;
        const display = route.display;
        return (
            <>
              {display && (!route.role || keycloak?.realmAccess?.roles.find((role) => role == route.role)) && (
                  <Box
                      className={
                        location.pathname == route.path
                            ? "menu-items menu-selected"
                            : "menu-items"
                      }
                  >
                    <Box className={route.style}>
                      <Link to={route.path}>
                        {Icon === undefined ? (
                            <Button>{route.name}</Button>
                        ) : (
                            <Button startIcon={<Icon/>}>{route.name} </Button>
                        )}
                      </Link>
                    </Box>
                  </Box>
              )}
            </>
        );
      })}
    </div>
    </Drawer>
    }
  </>;
};

export default Navigation;
