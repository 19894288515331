export const ROLE_ADMIN = "segpa-admin";

export function hasRole(
  desiredRole: string,
  keycloak?: import("keycloak-js").KeycloakInstance
): boolean | undefined {
  return (
    keycloak?.authenticated &&
    keycloak?.realmAccess?.roles.find((role: string) => role == desiredRole) !==
      undefined
  );
}
