import { lazy, LazyExoticComponent } from "react";
import { SvgIconComponent } from "@mui/icons-material";
import SchoolIcon from "@mui/icons-material/School";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import {ROLE_ADMIN} from "./model/role";

export interface AppRoute {
  exact: boolean;
  path: string;
  name: string;
  style: string;
  display: boolean;
  icon: SvgIconComponent | undefined;
  component?: LazyExoticComponent<() => JSX.Element>;
  role?: string;
}

const Trainings = lazy(() =>
  import("modules/trainings").then((module) => ({
    default: module.TrainingsIndexPage,
  }))
);

const Sectors = lazy(() =>
  import("modules/sectors").then((module) => ({
    default: module.SectorIndexPage,
  }))
);

const Diplomas = lazy(() =>
  import("modules/diplomas").then((module) => ({
    default: module.DiplomasIndexPage,
  }))
);

const Personalities = lazy(() =>
    import("modules/personality").then((module) => ({
      default: module.PersonalityIndexPage
    }))
);

const Home = lazy(() =>
  import(".").then((module) => ({
    default: module.HomePage,
  }))
);

export const routes: Record<string, AppRoute> = {
  home: {
    exact: true,
    path: "/",
    display: false,
    component: Home,
    name: "Dashboard",
    style: "menu-home",
    icon: undefined,
  },
  welcome: {
    exact: true,
    path: "/home",
    display: true,
    component: Home,
    name: "Dashboard",
    style: "menu-welcome",
    icon: AutoAwesomeMosaicIcon,
  },
  trainings: {
    exact: true,
    display: true,
    path: "/training",
    component: Trainings,
    name: "Etablissements",
    style: "menu-trainings",
    icon: SchoolIcon,
  },
  sectors: {
    exact: true,
    display: true,
    path: "/sectors",
    component: Sectors,
    name: "Métiers",
    style: "menu-trainings",
    icon: SchoolIcon,
  },
  diplomas: {
    exact: true,
    display: true,
    path: "/diplomas",
    component: Diplomas,
    name: "Diplômes",
    style: "menu-trainings",
    icon: SchoolIcon,
  },
  personality: {
    exact: true,
    display: true,
    path: "/personalities",
    component: Personalities,
    name: "Caractères",
    style: "menu-personalities",
    icon: SchoolIcon,
    role: ROLE_ADMIN
  },
};
