import "./App.css";
import { routes } from "./modules/core";
import Keycloak from "keycloak-js";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { Suspense } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import HomeIcon from "@mui/icons-material/Home";
import { Box, CircularProgress } from "@mui/material";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import Navigation from "./modules/core/components/Navigation";

//keycloak init options
const initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  onLoad: "login-required"
};

const keycloak = new Keycloak(initOptions)

function App() {
  
  const allRoutes = Object.values(routes);

  let location = useLocation();

  const getCurrentRoute = () => {
    return allRoutes.find((route) => route.path == location.pathname);
  };
  
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <Navigation routes={allRoutes} />
          <div className="main">
            {getCurrentRoute() != undefined && (
              <nav>
                <div>
                  <Link to="/home">
                    <HomeIcon className="nav-icon" />
                  </Link>
                  <span> / {getCurrentRoute().name}</span>
                </div>
                <h3>{getCurrentRoute().name}</h3>
              </nav>
            )}
            <Suspense
              fallback={
                <Box textAlign="center" m={2}>
                  <CircularProgress />
                </Box>
              }
            >
              <Switch>
                {allRoutes.map((route) => (
                  <Route
                    exact={route.exact}
                    path={route.path}
                    key={route.path}
                    component={route.component}
                  />
                ))}
              </Switch>
            </Suspense>
          </div>
        </div>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
