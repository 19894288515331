import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useState, useCallback, useEffect } from "react";

export const LoginPanel = () => {
  const { keycloak } = useKeycloak();

  const [username, SetUsername] = useState("");

  const login = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  const logout = useCallback(() => {
    keycloak?.logout();
  }, [keycloak]);

  useEffect(async () => {
    if (keycloak?.authenticated) {
      keycloak?.loadUserProfile();
      let count = 0;
      while (keycloak?.profile === undefined && count < 2000) {
        count++;
        await new Promise((r) => setTimeout(r, 10));
      }
      let user = keycloak?.profile?.username;
      console.log(keycloak);
      SetUsername(user.charAt(0).toUpperCase() + user.slice(1));
    }
  });

  return (
    <>
      {!keycloak?.authenticated ? (
        <>
          <Button startIcon={<LoginIcon />} onClick={login}>
            Admin
          </Button>
        </>
      ) : (
        <>
          <Button endIcon={<LogoutIcon />} onClick={logout}>
            {username}
          </Button>
        </>
      )}
    </>
  );
};

export default LoginPanel;
